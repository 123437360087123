import React from 'react'
import fichas from '../images/forms/fichas.png'
import poker from '../images/forms/poker.png'
import { Galeria } from './layout/Galeria'
export const Nosotros = () => {
    return (
        <div className=' fondoNosotros'>
            <div className=' container bordeDorado'>
                <div className='containerNosotros'>
                    <div className='containerMision'>
                        <div className='containerTextMision'>
                            <h2 className='navbar-brand text-center'>Nuestra Mision</h2>
                            <p>Proporcionar experiencias de entretenimiento emocionantes y seguras en nuestros cinco establecimientos,
                                priorizando el servicio excepcional, el juego responsable y la integridad en nuestras operaciones.</p>
                            <img loading="lazy" src={fichas} alt="fichas" className='imgIconosCasino' />
                        </div>
                        <div className='imgMision'></div>
                    </div>
                    <div className='containerMision'>
                        <div className='imgVision'></div>
                        <div className='containerTextMision'>
                            <h2 className='navbar-brand text-center'>Nuestra Vision</h2>
                            <p>Ser el destino de entretenimiento de casinos preferido en cada región donde operamos, liderando la innovación
                                en la industria y creando un ambiente acogedor y responsable para nuestros clientes, mientras contribuimos
                                positivamente a nuestras comunidades y buscamos una expansión global sostenible.</p>
                            <img loading="lazy" src={poker} alt="fichas" className='imgIconosCasino' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
