import React, { Fragment, useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../images/logo-removebg-preview.png'

export const NavBar = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };

    useEffect(() => {

        const handleScroll = () => {
            const isScrolled = window.scrollY > 50;
            if (isScrolled !== scrolled) {
                setScrolled(isScrolled);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrolled]);

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            const navbarHeight = document.getElementById('navbar').offsetHeight;
            window.scrollTo({
                top: section.offsetTop - navbarHeight,
                behavior: 'smooth'
            });
        }
    };
    return (
        <Fragment>
            
                <nav aria-label="Navbar Pague Y Gane LTDA" id="navbar">
                    <Link to="/" aria-label="Ir a inicio" className='linkLogo'>
                        <span className=" textLogo" href="/">Pague y Gane LTDA</span>
                    </Link>
                    <div className={`menuToggle${menuOpen ? ' open' : ''}`} onClick={toggleMenu}>
                        <i className={menuOpen ? 'bi bi-x' : 'bi bi-list'}></i>
                        <div className='brilloMoneda1'></div>
                        <div className='brilloMoneda2'></div>
                    </div>
                    <ul className={`navigation${menuOpen ? ' open' : ''}`} onClick={closeMenu}>
                        <li className="nav-item" aria-label="Ir a Inicio">
                            <NavLink to="/#banner" className="nav-link" onClick={() => scrollToSection('banner')}>INICIO</NavLink>
                        </li>
                        <li className="nav-item" aria-label="Ir acerca de nosotros">
                            <NavLink to="/#about" className="nav-link" onClick={() => scrollToSection('nosotros')}>NOSOTROS</NavLink>
                        </li>
                        <li className="nav-item" aria-label="Ir a servicios">
                            <NavLink to="/#services" className="nav-link" onClick={() => scrollToSection('galeria')}>GALERIA</NavLink>
                        </li>
                        <li className="nav-item" aria-label="Ir a contactanos">
                            <NavLink to="/#reviews" className="nav-link" onClick={() => scrollToSection('sedes')}>SEDES</NavLink>
                        </li>
                        <li className="nav-item" aria-label="Ir a Reviews">
                            <NavLink to="/#finanzas" className="nav-link" onClick={() => scrollToSection('finanzas')}>FINANZAS</NavLink>
                        </li>
                    </ul>
                    {menuOpen && (
                        <ul className='subMenuWrapAll'>
                            <li><NavLink to="/#banner" className="dropdown-item" id='subMenuLink' onClick={() => { scrollToSection('banner'); closeMenu(); }}>INICIO</NavLink></li>
                            <li><NavLink to="/#nosotros" className="dropdown-item" id='subMenuLink' onClick={() => { scrollToSection('nosotros'); closeMenu(); }}>NOSOTROS</NavLink></li>
                            <li><NavLink to="/#galeria" className="dropdown-item" id='subMenuLink' onClick={() => { scrollToSection('galeria'); closeMenu(); }}>GALERIA</NavLink></li>
                            <li><NavLink to="/#sedes" className="dropdown-item" id='subMenuLink' onClick={() => { scrollToSection('sedes'); closeMenu(); }}>SEDES</NavLink></li>
                            <li><NavLink to="/#finanzas" className="dropdown-item" id='subMenuLink' onClick={() =>{scrollToSection('finanzas');  closeMenu()}} >FINANZAS</NavLink></li>
                            <div className='containerLogo'>
                                <img src={Logo} alt="pagueygane Logo" className='imgLogo' />
                            </div>
                            <div className='bordeDoradoBottom'></div>
                        </ul>
                    )}
                    <div className='bordeDoradoBottom'></div>
                </nav>
        </Fragment >
    )
}
