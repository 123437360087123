import React from 'react'
import casino0 from '../../images/galeria/casino.jpg'
import casino1 from '../../images/galeria/casino1.jpeg'
import casino2 from '../../images/galeria/casino2.jpeg'
import casino3 from '../../images/galeria/casino3.jpeg'
import casino4 from '../../images/galeria/casino4.jpeg'
import casino5 from '../../images/galeria/casino5.jpeg'
import casino6 from '../../images/galeria/casino6.jpeg'
import casino7 from '../../images/galeria/casino7.jpeg'
import ruleta from '../../images/forms/ruleta.png'
import tragamonedas from '../../images/forms/tragamonedas.png'

export const Galeria = () => {
    return (
        <div className=' bordeDoradoDelgado'>
            <div className='fondoGaleria'>
                <div className='container'>
                    <h2 className='navbar-brand text-center'>Galeria</h2>
                    <p className='textEntretenimiento'>Experimenta la emoción en nuestro casino con la ruleta y las tragamonedas!</p>
                    <div className='containerJuegos '>
                        <div className='grapJuego'>
                            <img src={ruleta} alt="ruleta" />
                        </div>
                        <div className='grapJuego'>
                            <img loading="lazy" src={tragamonedas} alt="tragamonedas" />  
                        </div>
                    </div>
                </div>
                <div className='galeria container'>
                    <div className='wide'>
                        <img loading="lazy" src={casino1} alt="casino" className='imgCasino' id='c1' />
                    </div>
                    <div className='big'>
                        <img loading="lazy" src={casino3} alt="casino" className='imgCasino' id='c2' />
                    </div>
                    <div className='wide'>
                        <img loading="lazy" src={casino2} alt="casino" className='imgCasino' id='c3' />
                    </div>

                    <div className='big'>
                        <img loading="lazy" src={casino5} alt="casino" className='imgCasino' id='c4' />
                    </div>
                    <div className='big'>
                        <img loading="lazy" src={casino0} alt="casino" className='imgCasino' id='c5' />
                    </div>
                    <div className='big'>
                        <img loading="lazy" src={casino7} alt="casino" className='imgCasino' id='c6' />
                    </div>

                    <div className='wide'>
                        <img loading="lazy" src={casino6} alt="casino" className='imgCasino' id='c7' />
                    </div>
                    <div className='wide'>
                        <img loading="lazy" src={casino4} alt="casino" className='imgCasino' id='c8' />
                    </div>
                </div>
            </div>
        </div>
    )
}
