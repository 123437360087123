import React from 'react'
import Data from '../Data.json'
export const Ubicaciones = () => {
    return (
        <div className='containerUbicaciones'>
            <div className='container-fluid contenedorDirecciones'>
                <h2 className='navbar-brand'>Visitanos</h2>
                <p>Visitanos en nuestras sedes y disfruta de una experiencia única llena de diversión y premios. ¡Te esperamos</p>
                <div className='containerCard'>
                    {Data.map((result, index) =>
                    (
                        <div key={index}>
                            <div className='marginCard bordeDorado'>
                                <div className='boxCard'>
                                    <div className='imgMap'>
                                        <iframe src={result.mapa} width="320" height="140"  loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                                    </div>
                                    <div className='contentCard'>
                                        <h5>{result.nombre} </h5>
                                        <div className='textDireccion'>
                                            <i className="bi bi-geo-alt-fill"></i>
                                            <p>{result.barrio} </p>
                                            <p className='spanDireccion'>{result.direccion} </p>
                                        </div>
                                        <div className='textDireccion'>
                                            <i className="bi bi-telephone-fill"></i>
                                            <p>{result.telefono} </p>
                                        </div>
                                        <div className='containerButon'>
                                            <a href={result.btnMapa} target='_blank' className='buton'>
                                                <div className='btnContent'>
                                                    <span className='btnText'> Ver En Mapa</span>
                                                    <div className='brilloMoneda1'></div>
                                                    <div className='brilloMoneda2'></div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
