import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Inicio } from './components/Inicio';
import { Footer } from './components/layout/Footer';
import { NavBar } from './components/layout/NavBar';
import { LoadingPage } from './components/layout/LoadingPage';

const Banner = React.lazy(() => import('./components/layout/Banner'))
const Nosotros = React.lazy(() => import('./components/Nosotros'))
const Galeria = React.lazy(() => import('./components/layout/Galeria'))
const Sedes = React.lazy(() => import('./components/Ubicaciones'))
const Finanzas = React.lazy(() => import('./components/Finanzas'))

function App() {
  return (
    <Router>
      <NavBar></NavBar>
      <div className="App">
        <Suspense fallback={<LoadingPage></LoadingPage>}>
          <Routes>
            <Route path='/' element={<Inicio></Inicio>}></Route>
            <Route path='/banner' element={<Banner></Banner>}></Route>
            <Route path='/nosotros' element={<Nosotros></Nosotros>}></Route>
            <Route path='/galeria' element={<Galeria></Galeria>}></Route>
            <Route path='/sedes' element={<Sedes></Sedes>}></Route>
            <Route path='/finanzas' element={<Finanzas></Finanzas>}></Route>
          </Routes>
        </Suspense>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;