import React, { Fragment, Suspense } from 'react'
import { Banner } from './layout/Banner'
import { Nosotros } from './Nosotros'
import { Galeria } from './layout/Galeria'
import { Ubicaciones } from './Ubicaciones'
import { Finanzas } from './Finanzas'
import { LoadingPage } from './layout/LoadingPage'

export const Inicio = () => {
  return (
    <Fragment>
      <Suspense fallback={<LoadingPage></LoadingPage>}>
        <section id='banner'>
          <Banner></Banner>
        </section>
        <section id='nosotros'>
          <Nosotros></Nosotros>
        </section>
        <section id='galeria'>
          <Galeria></Galeria>
        </section>
        <section id='sedes'>
          <Ubicaciones></Ubicaciones>
        </section>
        <section id='finanzas'>
          <Finanzas></Finanzas>
        </section>
      </Suspense>
    </Fragment>
  )
}
