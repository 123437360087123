import React from 'react'
import Mujer from '../../images/mujerCasino.png'
import Circle from '../../images/forms/circle.png'
import Star from '../../images/forms/stars.png'
import Triangle from '../../images/forms/triangulo.png'
import coljuegos from '../../images/forms/iconoPrueba.jpeg'
import Iconocoljuegos from '../../images/forms/iconoCol.png'

export const Banner = () => {
    return (
        <div className='banner'>
            <div className='coljuegos'>
                <img src={coljuegos} alt="icono coljuegos" />
                <p>CONTRATO C1898</p>
            </div>
            <div className='bordeDorado'>
                <div className='contenidoBanner'>
                    <div className='contenedorImg'>
                        <img loading="lazy" src={Mujer} alt="MujerFeliz" />
                    </div>
                    <h1 className="navbar-brand">BIENVENIDOS</h1>
                    <h5>Descubre la emoción del juego y gana grandes premios. ¡Únete a nosotros hoy!</h5>
                    <div className='containerBtn'>
                        <a href='/#sedes' className='buton'>
                            <div className='btnContent'>
                                <span className='btnText'> Ver Ubicaciones</span>
                                <i className="bi bi-geo-fill" id='geo'></i>
                                <div className='brilloMoneda1'></div>
                                <div className='brilloMoneda2'></div>
                            </div>
                            <img src={Star} alt="Strella" className='star1' />
                            <img src={Star} alt="Strella" className='star2' />
                            <img src={Circle} alt="Circle" className='circle1' />
                            <img src={Circle} alt="Circle" className='circle2' />
                            <img src={Triangle} alt="Triangulo" className='triangle1' />
                            <img src={Triangle} alt="Triangulo" className='triangle2' />
                            <div className='btnShadow'></div>
                        </a>
                    </div>
                </div>
            </div>
            <div className='iconoColjuegos'>
                <img src={Iconocoljuegos} alt="icono coljuegos" />
            </div>
        </div>
    )
}
