import React, { useEffect, useState } from 'react'

export const LoadingPage = () => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress(prevProgress => {
                if (prevProgress < 100) {
                    return prevProgress + 1;
                } else {
                    return prevProgress;
                }
            });
        }, 100);

        return () => clearInterval(interval);
    }, []);
    return (
        <div className='containerLoading'>
            <div className='' >
                <div className="text-center navbar-brand">{progress}%</div>
                <div className='progress-bar'>
                    <div className='progress'></div>
                </div>
            </div>
        </div>
    )
}