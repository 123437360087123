import React from 'react'
import RUT from '../documents/rut.pdf'
import Camara from '../documents/camaraDeComercio.pdf'
import estadosFinancieros from '../documents/estadosFinancieros.pdf'
export const Finanzas = () => {
    return (
        <div className='bordeDoradoDelgado '>
            <div className='boxText'>
                <h2 className='navbar-brand text-center'>Finanzas</h2>
            </div>
            <div className='containerEstados'>
                <div className=''>

                </div>
                <div className='containerBotones'>
                    <h5>Regulacion del Casino </h5>
                    <div className='boxBotones'>
                        <div className='cajaInfo'>
                            <p>CERTIFICADO RUT</p>
                            <a href={RUT} target='_blank' className='buton'>
                                <div className='btnContent'>
                                    <span className='btnText'> VER</span>
                                    <div className='brilloMoneda1'></div>
                                    <div className='brilloMoneda2'></div>
                                </div>
                            </a>
                        </div>
                        <div className='cajaInfo'>
                            <p>CAMARA DE COMERCIO</p>
                            <a href={Camara} target='_blank' className='buton'>
                                <div className='btnContent'>
                                    <span className='btnText'> VER</span>
                                    <div className='brilloMoneda1'></div>
                                    <div className='brilloMoneda2'></div>
                                </div>
                            </a>
                        </div>
                        <div className='cajaInfo'>
                            <p>ESTADOS FINANCIEROS 2023-2022</p>
                            <a href={estadosFinancieros} target='_blank' className='buton'>
                                <div className='btnContent'>
                                    <span className='btnText'> VER</span>
                                    <div className='brilloMoneda1'></div>
                                    <div className='brilloMoneda2'></div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
